import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Design'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Design`}</h1>
    <p>{`The Arwes visual design is a result of analyzing and compiling different visual
aspects from many different games, movies, series, web designs, and even TV commercials
projects.`}</p>
    <p>{`The main sources of inspiration come from these awesome projects:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://robertsspaceindustries.com"
        }}>{`Star Citizen`}</a>{` (`}<a parentName="li" {...{
          "href": "https://robertsspaceindustries.com/starmap"
        }}>{`Starmap`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.halowaypoint.com/en-us/games"
        }}>{`Halo`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.imdb.com/title/tt1104001"
        }}>{`TRON: Legacy`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.harlanelam.com/#/jask/"
        }}>{`Jask HUD`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://creativemarket.com/dannehr/163951-Orbit-SciFi-UI-Kit"
        }}>{`Orbit SciFi UI Kit`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.behance.net/gallery/47272469/MARS-UI-Screen-Graphics"
        }}>{`MARS UI Screen Graphics`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://kvsr.itch.io/cyberpunk-ui-package"
        }}>{`Cyberpunk UI Package`}</a></li>
    </ul>
    <p>{`Some other multimedia projects compilations:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://videohive.net/item/hud-ui-pack-700/19326628"
        }}>{`Futuristic HUD UI Pack`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://videohive.net/item/hud/14206389"
        }}>{`SciFi HUD Pack`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=Y8w-2lzM-C4"
        }}>{`Binary Code`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://soundcloud.com/boom-library/sci-fi-designed"
        }}>{`Boom Sci-Fi Designed`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.imdb.com/title/tt1219827/"
        }}>{`Ghost in the Shell`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.cyberpunk.net"
        }}>{`Cyberpunk 2077`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://co.pinterest.com/romelperez07/futuristic-sci-fi-design"
        }}>{`Futuristic Sci-Fi Designs Board`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PLAIjpj9Un1BDevmUZSDrLIYxF3Cl5LspY"
        }}>{`Futuristic Sci-Fi Designs Playlist`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/playlist?list=PLmGEbmwqAA4IYqCuH3bHzTVVtdpG6N4IJ"
        }}>{`Sci-Fi Ambient Music Playlist`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.webdesign-inspiration.com/web-designs/style/futuristic"
        }}>{`Best Futuristic Web Designs`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      